import ReactDOM from "react-dom"
import App from "./App"
import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"
import { registerSW } from "ftl-core"

Sentry.init({
  dsn:
    process.env.REACT_APP_ENV === "dev"
      ? // ? "https://2f0e06a613024bbda49909d7fb82b761@o495931.ingest.sentry.io/5569515"
        "https://fb2a41b856d9496e94bfa8ff52b94102@o495931.ingest.sentry.io/5996804"
      : // : "https://d5db8a903e3a449dac084bb18cc0dd25@o502989.ingest.sentry.io/5587427",
        "https://e405fbb58bb84cc3a351b4f582054e7e@o502989.ingest.sentry.io/5996836",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0,
})

ReactDOM.render(<App />, document.getElementById("root"))

registerSW("/service-worker.js")
