import { lazy } from "react"
import { Switch } from "react-router-dom"
import { FTLErrorPage } from "ftl-uikit"
import { CountryAPI, PictureAPI, ProfileAPI, UtilsAPI } from "./repository"
import { PrivateRoute } from "ftl-core"
import { FTLProfilePage } from "ftl-dashboards-templates"
//pages

const CountriesList = lazy(() => import("./pages/Countries/List"))
const CitiesPage = lazy(() => import("./pages/Cities/List"))
const DetailCityPage = lazy(() => import("./pages/Cities/Detail"))
const ServiceZonesPage = lazy(() => import("./pages/ServiceZones/List"))
const DetailServiceZonePage = lazy(() => import("./pages/ServiceZones/Detail"))
const DetailTimeZonePage = lazy(() => import("./pages/TimeZones/Detail"))
const TimeZonesPage = lazy(() => import("./pages/TimeZones/List"))
const DetailOrganizationPage = lazy(
  () => import("./pages/Organizations/Detail")
)
const OrganizationsPage = lazy(() => import("./pages/Organizations/List"))
const DetailCourierPage = lazy(() => import("./pages/Couriers/Detail"))
const CouriersPage = lazy(() => import("./pages/Couriers/List"))
const DetailBrandsPage = lazy(() => import("./pages/Brands/Detail"))
const BrandsPage = lazy(() => import("./pages/Brands/List"))
const CodesFullInfoPage = lazy(() => import("./pages/Codes/Detail"))
const OrdersPage = lazy(() => import("./pages/Orders/List"))
const CountriesDetailPage = lazy(() => import("./pages/Countries/Detail"))

const CodesIndexPage = lazy(() => import("./pages/Codes"))

export const Routes = () => {
  return (
    <Switch>
      <PrivateRoute path="/profile">
        <FTLProfilePage
          getProfile={ProfileAPI.get}
          editProfile={ProfileAPI.edit}
          getCountry={CountryAPI.getById}
          getCountries={CountryAPI.getList}
          getDefaultCountry={() => UtilsAPI.getDefault("countries")}
          deleteProfilePicture={ProfileAPI.deletePicture}
          changeProfilePassword={ProfileAPI.changePassword}
          postPicture={PictureAPI.post}
        />
      </PrivateRoute>

      <PrivateRoute exact path={["/codes", "/codes/sended"]}>
        <CodesIndexPage page="codes" />
      </PrivateRoute>
      <PrivateRoute exact path="/codes/unsend">
        <CodesIndexPage page="enterSms" />
      </PrivateRoute>
      <PrivateRoute exact path="/codes/:id">
        <CodesFullInfoPage />
      </PrivateRoute>
      <PrivateRoute exact path="/countries/">
        <CountriesList />
      </PrivateRoute>
      <PrivateRoute exact path="/countries/new">
        <CountriesDetailPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/countries/:id">
        <CountriesDetailPage pageType="edit" />
      </PrivateRoute>
      <PrivateRoute exact path="/cities/">
        <CitiesPage />
      </PrivateRoute>
      <PrivateRoute exact path="/cities/new">
        <DetailCityPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/cities/:id">
        <DetailCityPage pageType="edit" />
      </PrivateRoute>
      <PrivateRoute exact path="/service-zones/">
        <ServiceZonesPage />
      </PrivateRoute>
      <PrivateRoute exact path="/service-zones/new">
        <DetailServiceZonePage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/service-zones/:id">
        <DetailServiceZonePage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/time-zones">
        <TimeZonesPage />
      </PrivateRoute>
      <PrivateRoute exact path="/time-zones/new">
        <DetailTimeZonePage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/time-zones/:id">
        <DetailTimeZonePage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/organizations/">
        <OrganizationsPage />
      </PrivateRoute>
      <PrivateRoute exact path="/organizations/new">
        <DetailOrganizationPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/organizations/:id">
        <DetailOrganizationPage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/brands/">
        <BrandsPage />
      </PrivateRoute>
      <PrivateRoute exact path="/brands/new">
        <DetailBrandsPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/brands/:id">
        <DetailBrandsPage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/couriers/">
        <CouriersPage />
      </PrivateRoute>
      <PrivateRoute exact path="/couriers/new">
        <DetailCourierPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/couriers/:id">
        <DetailCourierPage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/orders/:id?">
        <OrdersPage />
      </PrivateRoute>

      <PrivateRoute path={["*", "/404"]}>
        <FTLErrorPage
          title="Страницы не существует"
          message="Наверное, вы перешли по неправильной ссылке."
        />
      </PrivateRoute>
    </Switch>
  )
}
